import React from "react";
import { Image } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../src/images/logo.webp";
import { Link, useLocation } from "react-router-dom";

export const Header = () => {
  const location = useLocation();

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="bg-body-tertiary"
      sticky="top"
    >
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <Image src={logo} alt="Logo" height="60" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link className="navbar-padding" href={location.pathname !== "/" ? "/" : "#home"} smooth={true}>
              Home
            </Nav.Link>
            <Nav.Link className="navbar-padding" href={location.pathname !== "/" ? "/" : "#about"} smooth={true}>
              About Us
            </Nav.Link>
            <Nav.Link className="navbar-padding" href={location.pathname !== "/" ? "/" : "#services"} smooth={true}>
              Services
            </Nav.Link>
            <Nav.Link className="navbar-padding" href={location.pathname !== "/" ? "/" : "#why-curific"} smooth={true}>
              Why Curific
            </Nav.Link>
            <Nav.Link className="navbar-padding" href={location.pathname !== "/" ? "/" : "#testimonials"} smooth={true}>
              Testimonials
            </Nav.Link>
            <Nav.Link className="navbar-padding" href={location.pathname !== "/" ? "/" : "#contactUs"} smooth={true}>
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
