import React from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import LazyVideo from '../componant/HomeCarosel/LazyVideo';
import 'swiper/css';
import 'swiper/css/pagination';
import riceTreatment from "../images/rice-treatment.mp4";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { Header } from "../layout/Header";
import Footer from "../layout/Footer";
import BackPainTestimonial from '../componant/HomeCarosel/BackPainTestimonial';


const treatmentLists = [
    {
        image: "https://sanokc.com/wp-content/uploads/2024/04/Interior-Image-ICE.png",
        video: riceTreatment,
        title: "Rice",
        content: "RICE (Rest, Ice, Compression, Elevation) is the first-line treatment for many acute injuries."
    },
    {
        image: "https://www.ncoa.org/_next/image/?url=https%3A%2F%2Fassets-us-01.kc-usercontent.com%3A443%2Fffacfe7d-10b6-0083-2632-604077fd4eca%2F3c41434d-cfa9-41ec-8411-9face17d5aac%2FStretching-exercises-older-adults_Hero_iStock-852401700_2020-12_1336x614.jpg&w=3840&q=75",
        video: riceTreatment,
        title: "Exercise",
        content: "Physical therapy and strengthening exercises can reduce pain and improve mobility."
    },
    {
        image: "https://www.marylandaddictionrecovery.com/wp-content/uploads/2016/02/cover.jpg",
        video: riceTreatment,
        title: "Medications",
        content: "Pain relief medications like NSAIDs or muscle relaxants can help manage symptoms."
    },
    {
        image: "https://assets.team-consulting.com/wp-content/uploads/2023/02/16141730/Feature.jpg",
        video: riceTreatment,
        title: "Assistive & Operative",
        content: "Treatments like physical therapy, chiropractic care, or surgery for severe cases."
    },
    {
        image: "https://www.luxuriousmagazine.com/wp-content/uploads/2020/11/Thermage-FLX%E2%84%A2-Procedure-1.jpg",
        video: riceTreatment,
        title: "Surgery",
        content: "Surgery is reserved for extreme cases, like herniated discs or spinal stenosis."
    },
]

const BackPainPage = () => {
    return (
        <>
            <Header />
            <div className="backpain-page">
                <section className="backpain-hero-section text-center">
                    <Container>
                        <h1 class="one-text text-secondary mb-0 text-uppercase">Back<span class="ps-2 text-primary one-text">Pain</span></h1>
                    </Container>
                </section>
                <section className="what-back-pain-section">
                    <Container>
                        <Row className="align-items-center flex-column-reverse flex-lg-row">
                            <Col lg={6} xs={12} className="mt-3 mt-lg-0">
                                <h2 class="fs-1 fw-bold text-primary">What is Back Pain?</h2>
                                <p className="paragraph">
                                    Back pain is one of the most common reasons people seek medical help or miss work. Back pain is a leading cause of disability worldwide.From minor discomfort to more serious conditions, it can affect your daily life.
                                </p>
                                <p className="paragraph mb-0">
                                    Fortunately, measures can help prevent or relieve most back pain episodes, especially for people younger than age 60. If prevention fails, simple home treatment and using the body correctly often will heal the back within a few weeks. Surgery is rarely needed to treat back pain.
                                </p>
                            </Col>
                            <Col lg={6} xs={12}>
                                <LazyVideo
                                    controls
                                    autoPlay
                                    className="video-player w-100 video-img"
                                    muted
                                    src="https://curific-care.s3.amazonaws.com/web-video/video.mp4"
                                    type="video/mp4"
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="symptoms-section">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={6} xs={12}>
                                <div className="symptoms-img">
                                    <Image src="https://platform.vox.com/wp-content/uploads/sites/2/chorus/uploads/chorus_asset/file/20537762/Covid_Symptoms.png?quality=90&strip=all&crop=7.8125,0,84.375,100" fluid alt="Symptoms" />
                                </div>
                            </Col>
                            <Col lg={6} xs={12} className="mt-3 mt-lg-0">
                                <h2 className="text-primary fs-1 mb-3">Symptoms</h2>
                                <p className="paragraph">
                                    Back pain can range from a muscle aching to a shooting, burning or stabbing sensation. Also, the pain can radiate down a leg. Bending, twisting, lifting, standing or walking can make it worse.
                                </p>
                                <h5 className="mb-2 fw-bold">Common symptoms include:</h5>
                                <div className="text-secondary diagnosis-list text-bold">
                                    <ul>
                                        <li>Stiffness in the lower back</li>
                                        <li>Pain radiating down the leg</li>
                                        <li>Shooting or sharp pain</li>
                                        <li>Limited range of motion</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="treatment-section">
                    <Container>
                        <Row>
                            <h4 className="text-primary heading mb-4 text-center text-uppercase">Treatment</h4>
                            <Swiper
                                slidesPerView={1.4}
                                spaceBetween={20}
                                grabCursor={true}
                                className="mySwiper"
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Pagination]}
                                breakpoints={{
                                    768: {
                                        slidesPerView: 2.3,
                                    },
                                    992: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                        centeredSlides: false
                                    },
                                }}
                            >
                                {treatmentLists.map((treatment, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <Card className="border-0 treatment-card h-100">
                                                <div className="image-video-container">
                                                    <img src={treatment.image} alt="treatment" className="image" />
                                                    <video src={treatment.video} autoPlay='true' loop muted className="video" />
                                                </div>
                                                <Card.Body>
                                                    <Card.Title>{treatment.title}</Card.Title>
                                                    <Card.Text className="paragraph">
                                                        {treatment.content}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </Row>
                    </Container>
                </section>
                <BackPainTestimonial />
            </div>
            <Footer />
        </>
    );
};

export default BackPainPage;