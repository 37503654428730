import React, { useRef, useEffect, useState } from "react";

const LazyVideo = ({ src, type, poster, ...props }) => {
  const videoRef = useRef();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect();
          }
        });
      },
      {
        root: null, // relative to the viewport
        rootMargin: "0px",
        threshold: 0.1, // trigger when 10% of the video is visible
      }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);
  useEffect(() => {
    if (videoRef.current && isVisible) {
      videoRef.current.currentTime = "0.25";
    }
  }, [isVisible]);

  return (
    <video ref={videoRef} controls poster={poster} {...props}>
      {isVisible && <source src={src} type={type} />}
      Your browser does not support the video tag.
    </video>
  );
};

export default LazyVideo;
