import React from "react";
import LazyVideo from "./LazyVideo";
import 'swiper/css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Container, Row } from "react-bootstrap";

const testimonialImage = [
  {
    video: "https://curific-care.s3.amazonaws.com/web-video/testimonial_1.mp4",
  },
  {
    video: "https://curific-care.s3.amazonaws.com/web-video/testimonial_2.mp4",
  },
  {
    video: "https://curific-care.s3.amazonaws.com/web-video/testimonial_3.mp4",
  },
  {
    video: "https://curific-care.s3.amazonaws.com/web-video/testimonial_1.mp4",
  },
  {
    video: "https://curific-care.s3.amazonaws.com/web-video/testimonial_2.mp4",
  },
];

const testimonials = [
  {
    text: [
      "My self Mrs Prachi Karanjkar and I m from mumbai. I was suffering with knee ligament injury pain since 10+ yrs. before 3 months I fell on same injury the pain was unbearable sometimes. In MRI we got to know I hv 0.4 cm ligament tear in knee. I got to know from my relative and colleague about Curific. To avoide any kide of surgery I decided to give it a try. At first I met Dr. Dipen Patel and Dr Pragati they explained the issue and treatment plan in detail. In next 4 days my treatment gets started where I met Dr Nirali Gupta (physio.). She is the best so humble, caring, sometimes tough. She always there for me to support in every stage. I am very satisfied with her and of course Curific team who helped me to cure me. Thank you 🙏🏻",
    ],
    name: "Prachi",
    location: "(34 yrs. Old, Mumbai)",
  },
  {
    text: [
      "I suffered from sciatica nerve pain. I tried many treatments, but I got no relief in my pain. Someone told me about Curific. Firstly Dr. Pragati diagnosed my pain and explained why this pain occurs.Curific provided a doctor for me for online sessions. Dr. Nirali has excellent knowledge about treatment. She did the treatment with proper care and attention and has a very good behavior. I am satisfied with Dr. Nirali and Curific.",
    ],
    name: "Jitedra Jain",
    location: "(46 yrs. Old, Mumbai)",
  },
  {
    text: [
      "My recovery journey from a 10 year old Ankle Pain has been significant. All thanks to CURIFIC and Dr. Priyanka's expertise and unwavering support. My ankle feels stronger and more stable than ever before, and I am incredibly impressed from the treatment I received under her guidance at Curific with constant follow back on results. I recommend Curific treatment to everyone. Even I have gone ahead with a treatment for my family members.",
    ],
    name: "Kushagra Sharma",
    location: "(29 yrs. Old, Rajkot)",
  },
  {
    text: [
      "this review is on behalf of my mother in law, she had pain on her right knee and severe back pain. We took treatment from our home from Curific care. And in 20 sessions she got 80% relief. Both back and knee. Thank you Dr dipen, Dr pragati and Dr priyanka for amazing treatment. Specially Dr Priyanka and Dr. Pragati who were very approachable and kind and would listen calmly to all our issues. Highly recommend Curific for any kind of pain.",
    ],
    name: "Manpreet kaur",
    location: "(54 yrs. Old, Mumbai)",
  },
  {
    text: [
      "I had been suffering from knee pain for the past 15 years, and while doctors recommended knee replacement surgery, I wasn't comfortable with the idea of an operation.When I heard about Curific, I visited and on consultation of Dr Dipen and Dr Pragati, underwent laser treatment and physiotherapy I experienced a significant reduction in my knee pain without the need for surgery. Based on my personal experience, I highly recommend seeing Curific doctors for knee pain relief. Dr Nirali and Dr Fency are experts in their field. They are understanding, supportive and kind doctors. I am genuinely grateful to them.",
    ],
    name: "Sumanlata Rao",
    location: "(59 yrs. Old, Mumbai)",
  },
];

const BackPainTestimonial = () => {
  return (
    <>
      <section className="testimonial-section testimonial-video-section" id="testimonials">
        <Container className="padding-top">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h4 className="text-primary sub-heading">Testimonial</h4>
              <h2 className="heading text-secondary d-block mt-2">
                Hear What Our Happy Patients Say
              </h2>
            </div>
          </div>
          {/* <div className="row mt-5 justify-content-center">
                {dataBySlug?.testimonialsData?.video.map((item, index) => (
                  <div
                    key={index}
                    className="col-lg-4 col-md-6 col-12 text-center"
                  >
                    <div className="testimonial-image">
                      <LazyVideo
                        className="video-player w-100 video-img"
                        controls
                        muted
                        src={item.video}
                        type="video/mp4"
                      />
                    </div>
                  </div>
                ))}                
              </div> */}
          <Row className="mt-5">
            <Swiper
              slidesPerView={1.3}
              spaceBetween={20}
              grabCursor={true}
              className="mySwiper"
              breakpoints={{
                575: {
                  slidesPerView: 2.3,
                },
                768: {
                  slidesPerView: 3.3,
                },
                992: {
                  slidesPerView: 4.3,
                },
              }}
            >
              {testimonialImage.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="testimonial-image">
                    <LazyVideo
                      className="video-player w-100 video-img"
                      controls
                      muted
                      src={item.video}
                      type="video/mp4"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </Row>
        </Container>
      </section >
      <section className="testimonial-section testimonial-box">
        <Container>
          <Row className="mt-5">
            <Swiper
              slidesPerView={1.2}
              centeredSlides={true}
              spaceBetween={20}
              grabCursor={true}
              className="mySwiper"
              breakpoints={{
                1024: {
                  slidesPerView: 2.2,
                  centeredSlides: false,
                },
              }}
            >
              {testimonials?.map(
                (testimonial, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="testimonial-item">
                        <div className="testimonial-card">
                          <p className="paragraph testimonial-font">
                            {testimonial.text}
                          </p>
                          <h4 className="text-secondary">
                            {testimonial.name}
                          </h4>
                          <h5>
                            {testimonial.location}
                          </h5>
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                }
              )}
            </Swiper>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default BackPainTestimonial;
