import React from "react";
import { Header } from "../../layout/Header";
import Footer from "../../layout/Footer";

const TermsCondition = () => {
  return (
    <>
      <Header />
      <div style={{ backgroundColor: "#f7f7f7" }}>
        <div className="sec-div pt-5 tc container">
          <h3 className="primary-color mb-4 ">Terms & Conditions</h3>

          <ol style={{ lineHeight: "2" }}>
            <li>
              The medical equipment provided to you is the property of Curific
              Care Private Limited. and will be in good condition at the time of
              delivery. Once the treatment is over, the patient is supposed to
              return it back in the same condition.
            </li>
            <li>
              The equipment consists of a grey box alongside the device, a
              charging cable, and an adapter.
            </li>
            <li>The return must be done at the end of the treatment.</li>
            <li>
              Please note that Curific Care Private Limited. reserves the right
              to repossess the equipment without demand at any time if it is
              used in violation of the terms outlined in this agreement.
            </li>

            <li>
              Disclaimer of Liability: Curific shall not be responsible for any
              type of loss or damage to the medical equipment provided to the
              patient for the treatment. The patient will only be solely
              responsible for any damage incurred on the equipment.
            </li>

            <li>
              The patient accepts full responsibility and assumes all risk for
              any loss, damage, or injury that may occur as a result of the use
              of the medical equipment.
            </li>
            <li>
              The use of medical equipment is subject to combined limitations,
              and it is strictly prohibited for any person under the age of 18,
              under the influence of intoxicants or narcotics, or in an unsafe
              manner/environment. The patient at all times is required to follow
              the guidance of the prescribed Curific doctor.
            </li>

            <li>
              The patient acknowledges and agrees not to transfer ownership,
              sell, or lend the medical equipment to any third party, and not to
              tamper with or make modifications to the equipment.
            </li>
            <li>
              The patient agrees to only use the device for the purpose of
              treatment under the supervision of the Curific doctor and not to
              administer the device to any third party.
            </li>

            <li>
              The patient agrees to compensate and hold harmless Curific and its
              agents, officers, servants, and employees from any claims, losses,
              damages, or injuries arising from the use of the medical
              equipment, excluding cases of gross negligence or willful
              misconduct by Curific or its employees.
            </li>

            <li>
              Curific shall not be held liable for any actions or inactions of
              the patient or their family members, agents, servants, or
              employees.
            </li>

            <li>
              The patient agrees to promptly notify the Curific/ the attending
              Doctor of any accidents or damage resulting from the use of the
              medical equipment.
            </li>

            <li>
              An individual executing this agreement on behalf of a fiduciary,
              corporation, or other entity shall be personally and collectively
              responsible for fulfilling all obligations under this agreement.
            </li>

            <li>
              In the event of the patient’s default or failure to fulfill their
              obligations under this agreement, Curific reserves the right to
              immediately terminate the agreement by providing notice to the
              patient. Upon termination, Curific may take possession of the
              equipment and remove it from service. The patient will be
              responsible for any additional charges and will not be eligible
              for a refund of the deposit fee if the equipment is not
              recoverable.
            </li>

            <li>
              Before taking possession of the equipment, the patient is required
              to pay a security deposit of INR 5000/- as a guarantee for the
              equipment and any damages caused to it during the treatment. The
              deposit may be used by Curific to cover repair costs in the event
              of damages caused by the patient or their assistant. The patient
              will not be allowed to use the deposit as a substitute for any
              treatment package payment. If the patient breaches any terms of
              this agreement, they will forfeit the deposit as per law.
            </li>

            <li>
              The kit includes a transmission gel and an ice pack for one
              patient. No need to return them after treatment. Returning them
              will result in a Rs. 500/- fine deducted from the security
              deposit.
            </li>

            <li>
              Treatment starts upon device delivery to the patient’s home. No
              treatment on Sundays or public holidays. Complete the treatment
              cycle without any breaks as per the prescribed schedule.
            </li>

            <li>Patients must be respectful to the doctors.</li>
            <li>
              In the event of a dispute that cannot be resolved through
              discussion and negotiation, the issue will be resolved through the
              court of the appropriate jurisdiction located in Ahmedabad. The
              proceedings will be governed by the laws of India and conducted in
              English.
            </li>

            <li>
              The entire content of this form is protected by copyright laws.
              Reproduction, storage, or transmission of any part of this form
              without the permission of Curific Care Private Limited. is
              strictly prohibited. The information provided in this form is
              confidential and can be used in any legal proceedings.
            </li>

            <li>
              If any aspect is not addressed in this agreement, the organization
              shall decide at its discretion. The company reserves the right to
              modify all rights.
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsCondition;
