import React, { lazy } from "react";
import "../../App.css";

import { Header } from "../../layout/Header";
import Footer from "../../layout/Footer";
const AboutUs = lazy(() => import("../../componant/HomeCarosel/AboutUs"));
const MainCrosel = lazy(() => import("../../componant/HomeCarosel/MainCrosel"));
const RoadMap = lazy(() => import("../../componant/HomeCarosel/RoadMap"));
const TechBase = lazy(() => import("../../componant/HomeCarosel/TechBase"));
const Services = lazy(() => import("../../componant/HomeCarosel/Services"));
// const Header = lazy(() => import("./layout/Header"));
const SomefactAbout = lazy(() =>
  import("../../componant/HomeCarosel/SomefactAbout")
);
const Video = lazy(() => import("../../componant/HomeCarosel/Video"));
const Diagnosis = lazy(() => import("../../componant/HomeCarosel/Diagnosis"));
const Aalayam = lazy(() => import("../../componant/HomeCarosel/Aalayam"));
const WhyCurific = lazy(() => import("../../componant/HomeCarosel/WhyCurific"));
const TreatmentProtocol = lazy(() =>
  import("../../componant/HomeCarosel/TreatmentProtocol")
);
const Appointment = lazy(() =>
  import("../../componant/HomeCarosel/Appointment")
);
const Solution = lazy(() => import("../../componant/HomeCarosel/Solution"));
const Testimonial = lazy(() =>
  import("../../componant/HomeCarosel/Testimonial")
);
const BoardMembers = lazy(() =>
  import("../../componant/HomeCarosel/BoardMembers")
);
// const Footer = lazy(() => import("./layout/Footer"));

const Home = () => {
  return (
    <div className="home-page">
      <Header />
      <MainCrosel />
      <RoadMap />
      <AboutUs />
      <TechBase />
      <SomefactAbout />
      <Services />
      <Diagnosis />
      <Aalayam />
      <WhyCurific />
      <Video />
      <TreatmentProtocol />
      <Appointment />
      <Solution />
      <Testimonial />
      <BoardMembers />
      <Footer />
    </div>
  );
};

export default Home;
