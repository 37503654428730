import React from "react";
import { Header } from "../../layout/Header";
import Footer from "../../layout/Footer";
const NoPage = () => {
  return (
    <>
      <Header />
      <div
        className=" container"
        style={{ textAlign: "center", height: "23vh", marginTop: "30px" }}
      >
        <h1>404 Page Not Found!...</h1>
      </div>
      <Footer />
    </>
  );
};

export default NoPage;
