import React, { Fragment } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/home";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy/RefundPolicy";
import TermsCondition from "./pages/TermsCondtion/TermsCondtion";
import NoPage from "./pages/NoPage/NoPage";
import BackPainPage from "./pages/BackPain";

const App = () => {
  return (
    <div>
      <Routes>
        <Fragment>
          <Route path={"/"} element={<Home />} />
          <Route path={"/back-pain"} element={<BackPainPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsCondition />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/*" element={<NoPage />} />
        </Fragment>
      </Routes>
    </div>
  );
};

export default App;
